.newsLetterArea{
    padding: 74px 0 80px;
    background: #272c3f;
    .newsletterWrap{
        max-width: 443px;
        margin: auto;
        text-align: center;
        h3{
            font-size: 30px;
            font-weight: 500;
            margin-bottom: 40px;
            color: #c0b596;
        }
        .newsletterForm{
            position: relative;
            input{
                background: none;
                height: 50px;
                font-size: 17px;
                color: #fff;
                padding: 6px 80px 6px 30px;
                border: 0;
                -webkit-box-shadow: none;
                box-shadow: none;
                border: 1px solid rgb(192, 181, 150);
            }
            button{
                background: rgba(192, 181, 150,1);
                color: #fff;
                border: 0;
                outline: 0;
                position: absolute;
                right: 0;
                top: 0;
                width: 125px;
                height: 100%;
            }
        }
    }
}