.singleArea{
    padding: 100px 0px;
    .pr25{
        padding-right: 25px;
        @media (max-width:768px) {
            padding-right: 0;
        }
    }
    .pl25{
        padding-left: 25px;
        @media (max-width:768px) {
            padding-left: 0;
        }
    }
}