.contactusPageArea{
    padding: 100px 0px;
    .contactUsInfo{
        h3{
            font-size: 30px;
            font-weight: 400;
            margin-bottom: 30px;
        }
        h4{
            font-size: 24px;
            color: #666;
            margin-bottom: 10px;
            margin-top: 20px;
        }
        span{
            display: block;
        }
    }
    .contactUSForm{
        h3{
            margin-bottom: 40px;
            font-size: 30px;
        }
        .contactForm{
            .formInput{
                input,
                select,
                textarea{
                    background: #ebebeb;
                    border: none;
                    color: #495057;
                }
            }
        }
    }
}